import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getLocalStorageItem } from '../utils/localStorage';


export const userSlice = createSlice({
  name: 'user',
  initialState: () => {
    const localUser = getLocalStorageItem<IUser>('user') || null;
    return localUser as IUser | null;
  },
  reducers: {
    login: (_state, action: PayloadAction<WithRequired<IUser, 'token'>>) => {
      localStorage.setItem('user', JSON.stringify({ ...action.payload }));
      return { ...action.payload };
    },
    update: (state, action: PayloadAction<IUser>) => {
      localStorage.setItem('user', JSON.stringify({ ...state, ...action.payload }));
      return { ...state, ...action.payload };
    },
    logout: (_state) => {
      localStorage.removeItem('user');
      return null;
    },
  },
})

export type UserState = ReturnType<typeof userSlice.reducer>
export const userActions = userSlice.actions
export default userSlice.reducer