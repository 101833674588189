import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import { useDispatch } from "react-redux";
import logger from 'redux-logger';
import { IS_DEV } from "@common/constant";

const store =  configureStore({
  reducer: {
    user: userReducer,
  },
  middleware: IS_DEV ? [logger] as any : undefined,
});

export type TRootState = ReturnType<typeof store.getState>

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch 
export default store;