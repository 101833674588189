import { t } from "i18next";

export function makeMapWithTranslate<T extends Record<string, string>>(obj: T): Readonly<T> {
  return Object.keys(obj).reduce((acc, key) => {
    Object.defineProperty(acc, key, {
      get() {
        return t(obj[key]);
      },
    });
    return acc;
  }, {} as T);
}