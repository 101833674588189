/* eslint-disable react-refresh/only-export-components */
import { RouteObject, matchRoutes } from "react-router-dom";
import loadable, { DefaultComponent } from '@loadable/component';
import { 
  RechargeBillingIcon,
  RechargeBillingActivatedIcon,
  ApiManagementIcon,
  ApiManagementActivatedIcon,
  SettingsActivatedIcon,
  SettingsIcon,
  RobotActivatedIcon,
  RobotIcon,
  KnowledgeBaseActivatedIcon,
  KnowledgeBaseIcon,
  PluginIcon,
  PluginActiveIcon,
  KnowledgeAnalyzeIcon,
  KnowledgeAnalyzeActiveIcon,
  DashboardIcon,
  DashboardActiveIcon,
} from '@components/Icon';
import { Spin } from "antd";
import { MenuItemType } from "antd/es/menu/interface";

const Loading =  <div className="h-full flex items-center justify-center"><Spin /></div>;
const defaultOptions = { fallback: Loading };
const lazy = (fn: () => Promise<DefaultComponent<any>>) => loadable(fn, defaultOptions)

// loadable Component
const AllKnowledgeBase = lazy(() => import('@pages/AllKnowledgeBase'));
const KnowledgeBasedDetail = lazy(() => import('@pages/AllKnowledgeBase/KnowledgeBasedDetail'));
const AllRobots = lazy(() => import('@pages/AllRobots'));
const RechargeBilling = lazy(() => import('@pages/RechargeBilling'));
const APIManagement = lazy(() => import('@pages/APIManagement'));
const UserManagement = lazy(() => import('@pages/UserManagement'));
const NotFound = lazy(() => import('@pages/NotFound'));
const BotDetail = lazy(() => import('@pages/AllRobots/BotDetail'));
const PluginsPage = lazy(() => import('@pages/PluginsApplication'));
const KnowledgeAnalyze = lazy(() => import('@pages/KnowledgeAnalyze'));
const Dashboard = lazy(() => import('@pages/Dashboard'));

type RouteItem = Pick<RouteObject, 'path' | 'Component' | 'element'> & {
  children?: RouteItem[]
  label: MenuItemType['label']
  icon?: MenuItemType['icon']
  iconActivated?:  MenuItemType['icon']
  paths?: string[] // format: tree path array
  showInMenu?: boolean
}

type RouteExtraKeys = keyof Record<keyof Pick<RouteItem, 'label' | 'icon' | 'iconActivated' | 'paths'>, string>;
export const routeExtraKeys: RouteExtraKeys[] = ['label', 'icon', 'iconActivated','paths'];

const routes: RouteItem[] = [
  {
    label: '首页',
    icon: <DashboardIcon />,
    iconActivated: <DashboardActiveIcon />,
    path: 'dashboard',
    Component: Dashboard,
  },
  {
    label: '知识库',
    icon: <KnowledgeBaseIcon />,
    iconActivated: <KnowledgeBaseActivatedIcon />,
    path: 'knowledgeBase',
    children: [
      {
        label: '知识库列表',
        path: '/knowledgeBase/',
        Component: AllKnowledgeBase,
      },
      {
        label: '知识库详情',
        path: '/knowledgeBase/:id',
        Component: KnowledgeBasedDetail,
      }
    ]
  },
  {
    label: '知识分析',
    icon: <KnowledgeAnalyzeIcon />,
    iconActivated: <KnowledgeAnalyzeActiveIcon />,
    path: 'knowledgeAnalyze',
    Component: KnowledgeAnalyze,
  },
  {
    label: '机器人',
    icon: <RobotIcon />,
    iconActivated: <RobotActivatedIcon />,
    path: 'robots',
    children: [
      {
        label: '机器人列表',
        path: '/robots/',
        Component: AllRobots,
      },
      {
        label: '机器人详情',
        path: '/robots/:botId',
        Component: BotDetail,
      },
    ],
  },
  {
    label: '充值',
    icon: <RechargeBillingIcon />,
    iconActivated: <RechargeBillingActivatedIcon />,
    path: 'rechargeBilling',
    Component: RechargeBilling,
    showInMenu: false,
  },
  {
    label: 'API管理',
    icon: <ApiManagementIcon />,
    iconActivated: <ApiManagementActivatedIcon />,
    path: 'apiManagement',
    Component: APIManagement,
  },
  {
    label: '成员管理',
    icon: <SettingsIcon />,
    iconActivated: <SettingsActivatedIcon />,
    path: 'userManagement',
    Component: UserManagement,
  },
  {
    label: '插件应用',
    icon: <PluginIcon />,
    iconActivated: <PluginActiveIcon className="text-[#1F6DFF]" />,
    path: 'plugins',
    Component: PluginsPage,
  },
  {
    label: '404',
    path: '*',
    element: <NotFound />,
    showInMenu: false,
  },
];

// 添加paths字段
const addPathsToRoutes = (routes: RouteItem[], paths: string[] = []) => {
  routes.forEach(d => {
    d.paths = [...paths, d.path!];
    if (d.children) {
      addPathsToRoutes(d.children,  d.paths);
    }
  });
};
addPathsToRoutes(routes, []);

export const getDefaultPath = (useCurrentRouteIndex = false) => {
  // TODO: check user permission
  let path = `/main/${routes[0].path}`;
  if (useCurrentRouteIndex) {
    const currentRoute = (matchRoutes(routes, location.pathname.replace('/main', '')) || []).slice(-1)[0];
    const currentRouteIndex = currentRoute?.route?.paths?.[0];
    path = currentRouteIndex || path;
  }
  return path;
};

export default routes;
// export default Object.freeze(routes);
