import { message } from 'antd';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { CacheOptions, setupCache } from 'axios-cache-interceptor';
import { getLocalStorageItem } from './localStorage';
import { cloneDeep, merge } from 'lodash';
import { API } from '@common/constant';
import { t } from 'i18next';

export const AxiosCacheDefaultConfig: CacheOptions = {
  ttl: 200,
  cacheTakeover: false,
};
let showingForbiddenMessage = false;

const client = setupCache(axios.create({
  withCredentials: true,
  baseURL: API,
  timeout: 2 * 60 * 1000,
  timeoutErrorMessage: '网络连接超时，请重试',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
}), AxiosCacheDefaultConfig);

client.interceptors.request.use((config) => {
  if (config.url?.includes('/getAgency')) {
    return config;
  }
  const _config = config;
  const userInfo: IUser = getLocalStorageItem('user');
  const token = userInfo?.token;
  const curOrgId =  userInfo?.currentOrg?.id;

  if (token && !_config.headers?.Authorization) {
    merge(_config.headers, { Authorization: `Bearer ${token}` });
  }

  if (curOrgId && _config.params?.orgId === undefined) {
    _config.params = merge(_config.params, { orgId: curOrgId });
  }

  // TODO:token过长, yapi报错
  if (config.url?.startsWith('https://yapi')) {
    merge(_config.headers, { Authorization: `` });
  }
  return _config;
});
client.interceptors.response.use(null, (err: AxiosError<any>) => {
  console.error(err.response?.data?.message || err.message);
  const routingWhitelist = ['/ai/application'];
  if (routingWhitelist.includes(window.location.pathname)) {
    return void 0;
  }
  if (err.response?.status === 403) {
    !showingForbiddenMessage && message.warning(t('登录已失效，请重新登录'), undefined, () => {
      showingForbiddenMessage = false;
      location.href = '/auth/login';
    });
    showingForbiddenMessage = true;
  } else {
    message.error(err.response?.data?.message || err.message);
  }
  throw err;
});


const get = <T>(url: string, data = {}, options?: AxiosRequestConfig) => client.get<T>(url, { ...options, params: merge(cloneDeep(data), options?.params),  }).then(res => res.data);
const post = <T>(url: string, data = {}, options?: AxiosRequestConfig) => client.post<T>(url, data, options).then(res => res.data);
const put = <T>(url: string, data = {}, options?: AxiosRequestConfig) => client.put<T>(url, { params: data }, options).then(res => res.data);

const generateAxiosOptions = (options: AxiosRequestConfig = {}, token?: string) => {
  const tokenOptions = token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
  return merge(options, tokenOptions);
};

const handleApiResponse = (
  res: WithCode<unknown>,
  options: {
    successMessage?: string
    errCodeMap?: { [key: number | string]: string }
    success?: () => void
    fail?: () => void
  } = {}
) => {
  const { successMessage = '成功', errCodeMap = {}, success, fail } = options;
  if (res?.code === 0) {
    successMessage && message.success(t(successMessage));
    success?.();
  } else {
    const errMessage = errCodeMap[res.code] || res?.message || `${res?.code}${t('失败')}`;
    message.error(errMessage);
    fail?.();
  }
}

export { get, post, put, handleApiResponse, generateAxiosOptions };
export default { get, post, put };
