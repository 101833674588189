import { init, Replay, BrowserTracing, reactRouterV6Instrumentation, BrowserProfilingIntegration } from '@sentry/react';
import { ErrorEvent } from '@sentry/types';
import { getLocalStorageItem } from '@utils/localStorage.ts';
import { merge, pick } from 'lodash';
import { AxiosError } from 'axios';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const initSentry = () => {
  init({
    dsn: "https://96285201a92912597b207f73e8b87a4e@sentry.juzibot.com/2",
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1.0,
    sampleRate: 1,
    tracesSampleRate: 1,
    profilesSampleRate: 1.0,
    integrations: [
      new BrowserProfilingIntegration(),
      new Replay({
        maskAllInputs: false,
        maskAllText: false,
      }),
      new BrowserTracing({
        routingInstrumentation: reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    beforeSend: (event, hint) => {
      const localUser = getLocalStorageItem<IUser | null>('user');
      const addMore = (part: Partial<ErrorEvent>) => { merge(event, part) };
      if (localUser) {
        addMore({
          user: {
            ...pick(localUser, ['id', 'name', 'email']),
            orgId: localUser.currentOrg?.id,
            orgName: localUser.currentOrg?.name,
          },
        });
      }
      if (hint.originalException instanceof AxiosError) {
        const request = pick(hint.originalException.config, ['url', 'method', 'params', 'data']);
        const response = pick(hint.originalException.response, ['status', 'data']);
        addMore({
          extra: { request, response },
          fingerprint: [request.method!, request.url!, String(response.status)],
          tags: { 'isHttpError': true },
        });
      }
      return event;
    },
  })
};

export { initSentry }
