import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// i18next-browser-languagedetector插件
// 这是一个 i18next 语言检测插件，用于检测浏览器中的用户语言，
// 详情请访问：https://github.com/i18next/i18next-browser-languageDetector
import LanguageDetector from 'i18next-browser-languagedetector';
import chinese from './locales/zh';
import english from './locales/en';

const resources = {
  zh: {
    translation: chinese,
  },
  en: {
    translation: english,
  },
};
void i18n
  .use(LanguageDetector) // 检测当前浏览器语言 zh-CN
  .use(initReactI18next) // // 注入 react-i18next 实例
  .init({
    // 初始化 i18next
    // 配置参数的文档: https://www.i18next.com/overview/configuration-options
    resources, // 本地多语言数据
    fallbackLng: 'zh', // 默认当前环境的语言
    detection: {
      caches: ['localStorage', 'sessionStorage', 'cookie']
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
