import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from './stores/store';
import loadable from '@loadable/component';
import { getDefaultPath } from '@layouts/MainLayout/routes';
import { withProfiler } from '@sentry/react';
import UpgradeNotification from '@components/UpgradeNotification';
import { authApi } from '@apis/auth';
import { userActions } from '@stores/userReducer';
import { message } from 'antd';
import InfoIcon from '@assets/img/orgEnableInfo.png';
import QrcodePng from '@assets/img/qrcode.png';
import Link from 'antd/es/typography/Link';

// loadable Component
const AuthLayout = loadable(() => import('@layouts/AuthLayout'));
const MainLayout = loadable(() => import('@layouts/MainLayout'));
const AiApplication = loadable(() => import('@pages/AiApplication'));

function ErrorBoundary() {
  const error = useRouteError() as any;
  console.log(error, '----error');
  if (error?.message && (error.message.includes('Failed to fetch') || error.message.includes('Unable to preload'))) {
    window.location.reload();
    return null;
  }

  return (
    <div className="flex flex-col justify-center p-[50px]">
      <h1>Something went wrong!</h1>
      <p>{error?.message}</p>
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <div></div>,
  },
  {
    path: '/ai/application',
    element: <AiApplication />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/auth/*',
    element: <AuthLayout />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '/main/*',
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
  },
  {
    path: '*',
    element: <div>NotFound</div>,
  },
]);

function App() {
  const user: IUser | null = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();
  const [openQrcode, setOpenQrcode] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith('/main')) {
      if (user && !user.currentOrg?.enabled) {
        setOpenQrcode(true);
      } else {
        setOpenQrcode(false);
      }
    } else {
      setOpenQrcode(false);
    }
  }, [user]);

  useEffect(() => {
    handleInit();
  }, []);

  const handleInit = async () => {
    if (location.pathname === '/') {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        const res = await authApi.getUserByAuthCode({ code });
        if (res.code === 0) {
          const { userInfo, orgId, token } = res.data;
          const currentOrg = userInfo.orgs?.find(d => d.id === orgId) || userInfo.orgs?.[0];
          if (currentOrg) {
            dispatch(userActions.update({ ...userInfo, currentOrg, token }));
            location.href = getDefaultPath();
          }
        } else {
          void message.error('code无效');
          location.href = '/auth/login';
        }
      } else if (user) {
        location.href = getDefaultPath();
      } else {
        location.href = '/auth/login';
      }
    }
  };

  return (
    <div className="App h-[100vh]">
      {openQrcode && (
        <div className="absolute z-20 top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-[rgba(255,255,255,0.80)]">
          <div className="flex items-center">
            <img src={InfoIcon} alt="info" className="w-[48px] h-[48px]" />
            <span className="ml-[10px] text-[24px] font-medium">请扫码联系【句子互动-商务经理】进行开通使用</span>
          </div>
          <div className="flex p-[15px] mt-[35px] rounded-[8px] bg-[#fff]">
            <img src={QrcodePng} alt="qrcode" className="w-[234px] h-[234px]" />
          </div>
          <Link onClick={() => dispatch(userActions.logout())}>退出登录</Link>
        </div>
      )}
      <RouterProvider router={router} />
      <UpgradeNotification />
    </div>
  );
}
export default withProfiler(App);
