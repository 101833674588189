type JSONValue = string | number | boolean | null | undefined | JSONObject | JSONArray;
type JSONObject = { [key: string]: JSONValue };
type JSONArray = JSONValue[];

// 将一个 JSON 对象序列化为字符串
function serialize(obj: JSONValue): string {
  return JSON.stringify(obj);
}

// 将一个字符串反序列化为 JSON 对象
function deserialize(str: string): JSONValue {
  return JSON.parse(str);
}

// 从 localStorage 中读取一个 JSON 对象
export function getLocalStorageItem<T>(key: string): T {
  const value = localStorage.getItem(key);
  return (value ? deserialize(value) : null) as T;
}

// 将一个 JSON 对象写入 localStorage
export function setLocalStorageItem(key: string, value: JSONValue): void {
  localStorage.setItem(key, serialize(value));
}