import { versionApi } from '@apis/version';
import { UpdateTipsIcon } from '@components/Icon';
import { Button, ConfigProvider } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { validate, compare } from 'compare-versions'
import moment from 'moment';
import { useInterval } from 'react-use';

const UpgradeNotification: FC = () => {
  const [newVersion, setNewVersion] = useState('');

  const { refetch } = useQuery({
    queryKey: 'versionApi.getVersion',
    queryFn: versionApi.getVersion,
    refetchOnWindowFocus: true,
    staleTime: 300000,
    enabled: !newVersion,
    onSuccess: res => {
      const appVersion = (process.env.app_version as unknown as TVersion)?.version;
      const appBuildTime = (process.env.app_version as unknown as TVersion)?.build_time;
      const serverVersion = res?.version || '';
      const serverBuildTime = res?.build_time || '';
      if (!validate(appVersion) || !validate(serverVersion)) {
        return
      }
      if (res.ci_env === 'TEST') {
        if (compare(serverVersion, appVersion, '>=') && moment(serverBuildTime).diff(moment(appBuildTime)) > 0) {
          setNewVersion(`${serverVersion}(${moment(serverBuildTime).format('YYYY-MM-DD HH:mm:ss')})`);
        }
      } else {
        if (compare(serverVersion, appVersion, '>')) {
          setNewVersion(serverVersion);
        }
      }
    }
  });

  useEffect(() => {
    console.log('===当前版本：', process.env.app_version);
  }, [])

  useInterval(refetch, !newVersion ? 60 * 60 * 1000 : null,)

  const reload = () => {
    window.location.reload();
  }

  if (newVersion) {
    return (
      <div className='h-[4rem] fixed right-2 z-[1000] bottom-10 bg-[#34373E] px-4 text-white rounded-md flex items-center'>
        <UpdateTipsIcon className='mr-0.5' />
        <span className='text-sm'>系统已升级至<span className='mx-0.5'>{newVersion}</span>版本</span>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#009CFF',
            },
          }}
        >
          <Button size="small" type='primary' className='ml-10 !shadow-none' onClick={reload}>刷新</Button>
        </ConfigProvider>
      </div>
    )
  }
  return null
};

export default UpgradeNotification;
