import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './stores/store';
import zhCN from 'antd/locale/zh_CN';
import './i18n.ts';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import moment from 'moment';
import { initSentry } from '@utils/sentry.ts';
import { IS_DEV, SENTRY_DISABLED } from '@common/constant.ts';

moment.locale('zh-cn');
dayjs.locale('zh-cn');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

!SENTRY_DISABLED && !IS_DEV && initSentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <ConfigProvider
      locale={zhCN}
      autoInsertSpaceInButton={false}
      theme={{
        token: {
          colorPrimary: '#1F6DFF',
          borderRadius: 4,
          colorBorder: '#DDE3EA',
          colorTextPlaceholder: '#AAB9CA',
          colorFillQuaternary: '#F9FAFD',
          colorBorderSecondary: '#F0F2F9',
          colorError: '#FF3234',
          colorBgContainerDisabled: '#F6F7FB',
          colorTextDisabled: '#858585',
        },
        components: {
          Input: {
            borderRadius: 4,
            colorBorder: '#DDE3EA',
            colorTextPlaceholder: '#AAB9CA',
          },
        },
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </QueryClientProvider>,
  // </React.StrictMode>
);
